let senseiIntegration = require("./senseiIntegration");

function setup() {
    activateListeners();
    senseiIntegration.setLocationForCookbookErrorModal($(".cookbook").data("cookbook-location"));
}

function activateListeners() {
    $("#check-sensei-install-button").on("click",el =>{
        senseiIntegration.checkIfSenseiInstalled(hideInstallShowSendCookbook, displaySenseiCheckFailed);
        senseiIntegration.setupAndStartCheckSenseiSpinner($(el.target));
    });

    let sendCookbookButton = $(".add-cookbook-page .send-cookbook");
    if (sendCookbookButton.length === 0) {
        return;
    }

    checkIfSenseiIsInstalled()
    sendCookbookButton.on("click", () => {
        checkIfSenseiIsInstalled();
    });
}

function checkIfSenseiIsInstalled() {
    senseiIntegration.checkIfSenseiInstalled(hideInstallShowSendCookbook,showInstallHideSendCookbook);
}

function hideInstallShowSendCookbook() {
    $("#sensei-install").addClass("d-none");
    $("#cookbook-proceed").removeClass("d-none");
}

function showInstallHideSendCookbook() {
    $("#sensei-install").removeClass("d-none");
    $("#cookbook-proceed").addClass("d-none");
}

function displaySenseiCheckFailed() {
    let senseiCheckButton = $("#check-sensei-install-button");
    senseiCheckButton.addClass("text-danger");
    setTimeout(() => senseiCheckButton.removeClass("text-danger"), 1000);
}


module.exports = {setup}
