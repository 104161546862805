let lastLookedAtCookbook = "";
let _paq = window._paq = window._paq || [];
const trackEventMethod = "trackEvent";

function activateListeners() {
    $(".cookbook").on("mouseenter", element => {
        let cookbookId = $(element.target).data("cookbook-id");
        if (cookbookId === undefined || cookbookId === "") return;
        lastLookedAtCookbook = cookbookId;
    });

    $(".event-cookbook").on("click", element => {
        let action = element.target.innerText;
        sendMatomoEvent("CookbookEvent", action , lastLookedAtCookbook);
    });

    $(".event-recipe").on("click", element => {
        let action = element.target.innerText;
        let recipeId = element.target.dataset.recipeId;
        sendMatomoEvent("RecipeEvent", action, recipeId);
    });


    $(".event-copy-badge").on("click", element => {
        let currentCookbookId = $(".event-copy-badge").data("cookbook-id");
        sendMatomoEvent("CookbookEvent","Copy badge", currentCookbookId);
    });
}

function sendMatomoEvent(category, action, name) {
    //  format:
    //  Req         req      req     opt    opt
    // [Method, Category , Action , Name , Value]
    _paq.push([trackEventMethod, category, action, name]);
}

function sendSearchEvents(
    searchString,
    languageFilters,
    levelFilters,
    tagFilters,
    sortFilter,
    sortDirection
) {
    sendSearchEventIfPresent("query",searchString);
    sendSearchEventIfPresent("level", levelFilters);
    sendSearchEventIfPresent("language", languageFilters);
    sendSearchEventIfPresent("tag", tagFilters);
    sendSearchEventIfPresent("sort", sortFilter);
    sendSearchEventIfPresent("sortDirection", sortDirection);
}

function sendSearchEventIfPresent(type, value) {
    if (value && value !== "") sendSearchEvent(type, value);
}

function sendSearchEvent(type, value) {
    sendMatomoEvent("RecipeSearch", type, value);
}

module.exports = {activateListeners, sendSearchEvents}