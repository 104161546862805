import * as cookbooks from "./cookbooks";
import * as recipes from "./recipes";
import * as addCookbooks from "./addCookbook";
import * as copyText from "./copy-text";
import * as recipeDetails from "./recipeDetails";
import * as senseiIntegration from "./senseiIntegration";
import * as introModal from "./introModal";
import * as matomoIntegration from "./matomoIntegration";

matomoIntegration.activateListeners();
cookbooks.activateListeners();
recipes.setup();
addCookbooks.setup();
copyText.activateListeners();
recipeDetails.setup();
senseiIntegration.setup();
introModal.setup();