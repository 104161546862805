let senseiIntegration = require("./senseiIntegration");

const introCookieValue = "introduced=true"

function setup() {
    senseiIntegration.checkIfSenseiInstalled(storeIntroducedCookie, displayIntroWhenUserIsNotIntroduced);

    $("#senseiIntroductionModal #start-using-sensei").on("click", () => {
        storeIntroducedCookie();
        closeIntroModal();
    });

    $("#senseiIntroductionModal .close").on("click", () => {
        storeIntroducedCookie();
        closeIntroModal();
    });
}

function displayIntroWhenUserIsNotIntroduced() {
    let cookie = document.cookie;
    if (!cookie.includes(introCookieValue)) {
        $("#senseiIntroductionModal").modal("show");
    }
}

function storeIntroducedCookie() {
    let date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 year
    document.cookie = introCookieValue + ";expires=" + date.toUTCString() + ";path=/";
}

function closeIntroModal() {
    $("#senseiIntroductionModal").modal("hide");
}

module.exports = {setup}