function activateListeners() {
    const copyButtons = document.querySelectorAll(".copy-text");
    copyButtons.forEach(button => copy(button));
}

function copy(element) {
    element.addEventListener("click", () => {
        copyToClipboard(
            element.dataset.copy,
            () => showToolTip("Copied!", element),
            ()  => console.log("Failed to copy to clipboard!")
        );
    });
}

function copyToClipboard(text, success, error) {
    navigator.clipboard.writeText(text).then(success).catch(error);
}

function showToolTip(text, element) {
    let jqueryElement = $(element);
    jqueryElement.attr("title", text)
        .tooltip("enable")
        .tooltip('_fixTitle')
        .tooltip('show');
    setTimeout(() => cleanToolTip(jqueryElement), 1500);
}

function cleanToolTip(element) {
    element.tooltip('_fixTitle')
        .tooltip("hide")
        .tooltip("disable");
}

module.exports = {activateListeners, showToolTip}