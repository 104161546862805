const matomoIntegration = require("./matomoIntegration");

function setup() {
    activateListeners();
    setSelectedFilterAmount();
}

function activateListeners() {
    let recipeSearchButton = $(".recipes-search-page #recipesSearchButton");
    recipeSearchButton.on("click", () => search());

    //only when the recipe search button is present we send the events
    if (recipeSearchButton.length > 0)
        sendEvents();

    $(".recipes-search-page #recipesSearchInput").bind("keypress", function(e) {
        if (e.keyCode === 13) search();
    });
    $(".recipes-search-page #clearFiltersButton").on("click", () => clearFiltersAndSearch());

    $(".recipes-search-page input[type='checkbox']").on("click", () =>  search());
    $(".recipes-search-page select[name='sort_select']").on("change", () => search());

    $(".recipes-search-page .selectable-tag").on("click", function () {
        selectTagAndSearch($(this)[0]);
    });

    $(".recipes-search-page input[name='sort_direction']").on("click", () => search());

    // recipedetails
    $(".recipe-details-page .selectable-tag").on("click", function () {
        searchSingleSelectedTag($(this)[0])
    });
}

function sendEvents() {
    let languageFilters = $('input[name=language_filter]:checked');
    let levelFilters = $('input[name=level_filter]:checked');
    let search = $("#recipesSearchInput")[0];
    let tagFilters = $('input[name=tag_filter]:checked');
    let sort = $("option[name=sort_filter]:selected")[0];
    let sortDirection = $("input[name=sort_direction]:checked")[0];

    let languageFiltersValue = "";
    let levelFiltersValue = "";
    let searchValue = "";
    let tagFiltersValue = "";
    let sortValue = "";
    let sortDirValue = "";

    if (languageFilters.length > 0)
        languageFiltersValue = commaSeparateValues(languageFilters);

    if (tagFilters.length > 0)
        tagFiltersValue = commaSeparateValues(tagFilters);

    if (levelFilters.length > 0)
        levelFiltersValue = commaSeparateValues(levelFilters);

    if (search.value !== "")
        searchValue = search.value;

    if (sort.value !== "")
        sortValue = sort.value;

    if (sortDirection.value !== "")
        sortDirValue = sortDirection.value;

    matomoIntegration.sendSearchEvents(
        searchValue,
        languageFiltersValue,
        levelFiltersValue,
        tagFiltersValue,
        sortValue,
        sortDirValue
    );
}

function search() {
    let languageFilters = $('input[name=language_filter]:checked');
    let levelFilters = $('input[name=level_filter]:checked');
    let search = $("#recipesSearchInput")[0];
    let tagFilters = $('input[name=tag_filter]:checked');
    let sort = $("option[name=sort_filter]:selected")[0];
    let sortDirection = $("input[name=sort_direction]:checked")[0];

    if (search === undefined &&
        languageFilters.length === 0 && levelFilters.length === 0 && tagFilters.length === 0 && sort === undefined)
    {
        window.location.href = "/recipes"
        return;
    }

    redirectToRecipesWith(
        search.value,
        commaSeparateValues(languageFilters),
        commaSeparateValues(levelFilters),
        commaSeparateValues(tagFilters),
        sort.value,
        sortDirection.value
    );

}

function redirectToRecipesWith(search, languageFilters, levelFilters, tagFilters, sort, sortDirection) {
    window.location.href = createRedirectUrl(search, languageFilters, levelFilters, tagFilters, sort, sortDirection);
}

function createRedirectUrl(search, languageFilters, levelFilters, tagFilters, sort, sortDirection) {
    let baseUrl = "/recipes?query=" + search;

    if (languageFilters !== "" && languageFilters !== undefined) {
        baseUrl += "&language=" + languageFilters;
    }

    if (levelFilters !== "" && levelFilters !== undefined) {
        baseUrl += "&level=" + levelFilters;
    }

    if (tagFilters !== "" && tagFilters !== undefined) {
        baseUrl += "&tag=" + tagFilters;
    }

    if (sort !== "" && sort !== undefined) {
        baseUrl += "&sortBy=" + sort;
    }

    if (sortDirection !== "" && sortDirection !== undefined) {
        baseUrl += "&sortDirection=" + sortDirection;
    }

    return baseUrl;
}

function setSelectedFilterAmount() {
    $(".recipes-search-page #filterAmount").text("(" + $(".recipes-search-page input[type='checkbox']:checked").length + ")");
}

function selectTagAndSearch(element) {
    let value = element.dataset.value
    let type = element.dataset.type
    let id = ""

    if (type === "tag") {
        id = "#tag_id_" + value;
    }

    if (type === "level") {
        id = "#level_id_" + value;
    }

    if (type === "language") {
       id = "#language_id_" + value;
    }

    $(id).prop("checked", true);
    search();
}

function searchSingleSelectedTag(element) {
    let type = element.dataset.type

    let languageFilters = undefined;
    let levelFilters = undefined;
    let tagFilters = undefined;

    switch (type) {
        case "tag":
            tagFilters = element.dataset.value;
            break;
        case "level":
            levelFilters = element.dataset.value;
            break;
        case "language":
            languageFilters = element.dataset.value;
            break;
    }

    redirectToRecipesWith(
        "",
        languageFilters,
        levelFilters,
        tagFilters,
        "Title",
        "asc"
    );
}

function clearFiltersAndSearch() {
    $(".recipes-search-page input[type='checkbox']").prop("checked", false);
    search();
}

function commaSeparateValues(filters) {
    let filterRedirect = "";

    if (filters.length === 0) {
        return filterRedirect;
    }

    for (let i = 0; i < filters.length; i++) {
        if (i > 0) {
            filterRedirect += ",";
        }
        filterRedirect += filters[i].value;
    }

    return filterRedirect;
}

module.exports = {setup, commaSeparateValues, createRedirectUrl}