const url = "http://127.0.0.1";
const ports = ["63342", "63343", "63344"];
const copyText = require("./copy-text");

const minReqSenseiVersionForSendRecipe = "2021.2.9";

/* === COMMON === */

function setup() {
    setupMarketplaceWidgetIfRequired();
}

function setupMarketplaceWidgetIfRequired() {
    $(function() {
        if ($("#sensei-install-frame").length > 0) {
            MarketplaceWidget.setupMarketplaceWidget('install', 14015, "#sensei-install-frame");
        }
    });
}

function getIJInstanceOnPort(port) {
    return fetch(`${url}:${port}/api.sensei.getInfo`)
        .then(r => r.status < 400 ? r.json() : Promise.reject(`Failed to get info for IntelliJ instance at ${port}`))
        .then(data => Object.assign(data, {port}))
        .catch(err => undefined);
}

function findAvailableIJVersions() {
    const instancesPromises = ports.map(getIJInstanceOnPort);
    return Promise.all(instancesPromises).then(instances => instances.filter(i => i !== undefined));
}

function findAvailableIJVersionsWithMinSenseiVersion(minVersion) {
    const instances = findAvailableIJVersions();
    instances.then(versions => findAndSetHighestFoundSenseiVersion(versions));
    return instances.then(v => v.filter(i => isFoundVersionHigherThanMinVersion(minVersion, i.senseiVersion)));
}

function isFoundVersionHigherThanMinVersion(minVersion, foundVersion) {
    let splitMinVersion = minVersion.split(".");
    let splitFoundVersion = foundVersion.split("-")[0].split(".");

    return IsFoundHigherOrEqualToMinVersion(splitMinVersion, splitFoundVersion, 0, splitMinVersion.length - 1);
}

function IsFoundHigherOrEqualToMinVersion(splitMinVersion, splitFoundVersion, index, maxIndex) {
    let minValue = parseInt(splitMinVersion[index]);
    let currValue = parseInt(splitFoundVersion[index]);

    if (index === maxIndex) return minValue <= currValue

    if (minValue > currValue) return false;
    if (minValue < currValue) return true;

    return IsFoundHigherOrEqualToMinVersion(splitMinVersion, splitFoundVersion, index + 1, maxIndex);
}

function findAndSetHighestFoundSenseiVersion(versions) {
    let highestVersion = findHighestSenseiVersion(versions);
    let currentVersionElement = document.getElementById("highestFoundSenseiVersion");
    if (highestVersion === "0") {
        currentVersionElement.textContent = "Current Sensei version: none";
        return;
    }
    currentVersionElement.textContent = "Current Sensei version: " + highestVersion;
}

function findHighestSenseiVersion(versions) {
    let highestSenseiVersionFound = "0";
    for (let i = 0; i < versions.length; i++) {
        if (versions[i].senseiVersion > highestSenseiVersionFound) {
            highestSenseiVersionFound = versions[i].senseiVersion;
        }
    }
    return highestSenseiVersionFound;
}

function showToolTipIfRequired(showToolTip, button) {
    if (showToolTip) {
        copyText.showToolTip("Sent to sensei", button);
    }
}

function checkIfSenseiInstalled(onInstalled, onNotInstalled) {
    let versions = findAvailableIJVersions();
    versions.then(versions => {
        if (versions.length === 0) {
            onNotInstalled();
        } else {
            onInstalled();
        }
    });
}


/* === COOKBOOKS === */

// CORE
function findIJVersionsAndSendCookbookToSensei(cookbook, button) {
    findAvailableIJVersions().then(versions => {
        if (versions.length === 0) {
            configureAndShowSendCookbookToSenseiErrorModal(cookbook);
        } else if (versions.length === 1) {
            sendCookbookToSenseiAndHandleResponse(versions[0].port, cookbook, button);
            hideErrorModal();
        } else {
            configureAndShowAvailableIJInstancesModalForCookook(versions, cookbook);
            hideErrorModal();
        }
    });
}

function sendCookbookToSenseiAndHandleResponse(port, cookbook, button) {
    sendCookbookToSensei(port, cookbook)
        .then(() => showToolTipIfRequired(false, button))
        .catch(() => configureAndShowSendCookbookToSenseiErrorModal(cookbook));
}

function sendCookbookToSensei(port, cookbook) {
    const changedName = cookbook.name.toLowerCase().replace(/ /g, "_");
    return fetch(`${url}:${port}/api.sensei.addCookbook?id=${changedName}&location=${cookbook.location}&description=${cookbook.description}`, {
        method: "POST",
    }).then(r => r.status < 400 ? r.text() : Promise.reject());
}

// ERROR HANDLING
function configureAndShowSendCookbookToSenseiErrorModal(cookbook) {
    setLocationForCookbookErrorModal(cookbook.location);
    setupTryAgainButton(cookbook);
    showErrorModal();
}

function setLocationForCookbookErrorModal(location) {
    let locationInput = $("#error-modal-cookbook-location");
    let locationCopyButton = $("#tooltip-target-error-modal-cookbook-location");

    if (locationInput.length === 0 || locationCopyButton.length === 0) {
        return;
    }

    locationInput.val(location);
    locationCopyButton.attr("data-copy", location);
}

function setupTryAgainButton(cookbook) {
    let tryAgainButton = $("#try-send-cookbook-again");
    tryAgainButton.off();
    tryAgainButton.on("click", e => {
        findIJVersionsAndSendCookbookToSensei(cookbook, e.target);
        setupAndStartCheckSenseiSpinner(tryAgainButton);
    });
}

function setupAndStartCheckSenseiSpinner(tryAgainButton) {
    let spinnerContainer = $(".sensei-install-spinner-container");
    let spinner = spinnerContainer.find(".check-sensei-spinner");
    let checkFailedContainer = $(".sensei-check-failed");

    //hide try again, show spinner
    spinner.removeClass("d-none");
    tryAgainButton.addClass("d-none")
    spinnerContainer.removeClass("d-none");

    setTimeout(() => {
        //hide spinner, show failed
        checkFailedContainer.removeClass("d-none");
        spinner.addClass("d-none");

        setTimeout(() => {
            //hide failed, show try again
            tryAgainButton.removeClass("d-none");
            checkFailedContainer.addClass("d-none")
            spinnerContainer.addClass("d-none");

        }, 1500)
    }, 1000)
}

// MULTIPLE INSTANCES
function configureAndShowAvailableIJInstancesModalForCookook(instances, cookbook) {
    $("#confirm-version-button-container").empty();
    instances.forEach(i => {
        cookbook.name = cookbook.name.replace("'", "");
        $("#confirm-version-button-container").append(ijVersionForCookbookButtonHtmlTemplate(
            i.intellijVersion,
            i.port,
            cookbook
        ));
    });
    $("#confirmVersionModal").modal('show');
}

function ijVersionForCookbookButtonHtmlTemplate(version, port, cookbook) {
    const button = $('<button class="ijversion btn btn-primary m-1" data-dismiss="modal"></button>');
    button.attr('data-ij-port', port);
    button.attr('data-cookbook-id', cookbook.id);
    button.attr('data-cookbook-name', cookbook.name);
    button.attr('data-cookbook-location', cookbook.location);
    button.attr('data-cookbook-description', cookbook.description);
    button.on("click", () => onIntelliJVersionForCookbookButtonClick(port, cookbook))
    button.text(version);
    return button[0];
}

function onIntelliJVersionForCookbookButtonClick(port, cookbook) {
    sendCookbookToSenseiAndHandleResponse(port, cookbook);
}


/* === RECIPES ===  */

// CORE
function findIJVersionsAndSendRecipeToSensei(recipe, button) {
    findAvailableIJVersionsWithMinSenseiVersion(minReqSenseiVersionForSendRecipe)
        .then(versions => {
            if (versions.length === 0) {
                showErrorModal(recipe);
            } else if (versions.length === 1) {
                let shouldShowToolip = button.id !== "check-sensei-install-button";
                sendRecipeToSenseiAndHandleResponse(versions[0].port, recipe, button, shouldShowToolip);
                hideErrorModal();
            } else {
                configureAndShowAvailableIJInstancesModalForRecipes(versions, recipe);
                hideErrorModal();
            }
        });
}

function sendRecipeToSenseiAndHandleResponse(port, recipe, button, showToolTip) {
    sendRecipeToSensei(port, recipe)
        .then(() => showToolTipIfRequired(showToolTip, button))
        .catch(() => showErrorModal(recipe));
}

function sendRecipeToSensei(port, recipe) {
    let fetchUrl =`${url}:${port}/api.sensei.addRecipe`;
    return fetch(fetchUrl , {
        method: "POST",
        body: JSON.stringify(recipe)
    }).then(r => r.status < 400 ? r.text() : Promise.reject());
}

// ERROR HANDLING
function showErrorModal() {
    $("#errorModal").modal("show");
}

function hideErrorModal() {
    $("#errorModal").modal("hide");
}

// MULTIPLE INSTANCES
function configureAndShowAvailableIJInstancesModalForRecipes(instances, recipe) {
    $("#confirm-version-button-container").empty();
    instances.forEach(i => {
        $("#confirm-version-button-container").append(ijVersionForRecipeButtonHtmlTemplate(
            i.intellijVersion,
            i.port,
            recipe
        ));
    });
    $("#confirmVersionModal").modal('show');
}

function ijVersionForRecipeButtonHtmlTemplate(version, port, recipe) {
    const button = $('<button class="btn btn-primary m-1" data-dismiss="modal"></button>');
    button.text(version);
    button.on("click", e => onIntelliJVersionForRecipeButtonClick(e, recipe, port))
    return button[0];
}

function onIntelliJVersionForRecipeButtonClick(event, recipe, port) {
    sendRecipeToSenseiAndHandleResponse(port, recipe, $(event.target), false);
}


module.exports = {
    findIJVersionsAndSendCookbookToSensei,
    findIJVersionsAndSendRecipeToSensei,
    checkIfSenseiInstalled,
    setLocationForCookbookErrorModal,
    setupAndStartCheckSenseiSpinner,
    setup,

    /* below are exported for testing purposes */
    findAvailableIJVersions,
    findAvailableIJVersionsWithMinSenseiVersion,
    findHighestSenseiVersion,
    sendCookbookToSensei,
    sendRecipeToSensei
}
