const senseiIntegration = require("./senseiIntegration");

function setup() {
    document.querySelectorAll(".send-recipe").forEach(activateSendRecipeButton);
}

function activateSendRecipeButton(element) {
    const yaml = $(element).data("recipe-yaml");
    const descriptionContent = $(element).data("recipe-description-content");

    $(element).on("click", () =>
        senseiIntegration.findIJVersionsAndSendRecipeToSensei(
            {yaml, descriptionContent},
            element
    ));
}

module.exports = {setup}