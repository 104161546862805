const senseiIntegration = require("./senseiIntegration");

function activateListeners() {
    $("#installModal").on('shown.bs.modal', () => $("#errorModal").modal('hide'));
    document.querySelectorAll('.cookbook').forEach(activateListenersForCookbook);
}

function activateListenersForCookbook(element) {
    const id = $(element).data("cookbook-id");
    const name = $(element).data("cookbook-name");
    const location = $(element).data("cookbook-location");
    const description = $(element).data("cookbook-description");
    const cookbook = {id, name, location, description};

    $(element).find(".send-cookbook").on('click', el => {
        senseiIntegration.findIJVersionsAndSendCookbookToSensei(cookbook, el.target);
    });
    $(element).find(".copy-cookbook").on('click', e => {
        e.preventDefault();
        copyToClipboard(
            location,
            () => showToolTip("Copied!", cookbook.id),
            () => console.log("Failed to copy to clipboard!")
        );
    });
}

function copyToClipboard(text, success, error) {
    navigator.clipboard.writeText(text).then(success).catch(error);
}

function showToolTip(text, id) {
    const tooltipElement = $("#tooltip-target-" + id);
    tooltipElement.attr("title", text).tooltip('_fixTitle').tooltip('show');
    setTimeout(() => tooltipElement.tooltip('hide'), 1500);
}

module.exports = {activateListeners}